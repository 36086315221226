import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramShareButton,
    TelegramIcon,
    PocketShareButton,
    PocketIcon,
    RedditShareButton,
    RedditIcon,
} from "react-share";
import { createShareURL } from "@/utils/urls";

type ShareWidgetProps = {
    headerText?: string;
    url: string;
    title: string;
    excerpt: string;
    size?: number;
};
export default function ShareWidget({
    url,
    title,
    excerpt,
    size = 32,
    headerText = "Share this page!",
}: ShareWidgetProps) {
    return (
        <aside className="mt-5 mb-3 alert alert-success">
            <p>{headerText}</p>
            <EmailShareButton
                aria-label="Email this page to a friend"
                url={createShareURL("email", url)}
                subject={`Check out: ${title}`}
                body={excerpt}
            >
                <EmailIcon size={size} round={true} />
            </EmailShareButton>{" "}
            <FacebookShareButton
                aria-label="Share on Facebook"
                url={createShareURL("facebook", url)}
                // quote={excerpt}
                hashtag="artofmemory"
            >
                <FacebookIcon size={size} round={true} />
            </FacebookShareButton>{" "}
            <TwitterShareButton
                aria-label="Share on Twitter"
                url={createShareURL("twitter", url)}
                title={title}
                related={["@artofmemory_com", "@memoryleague"]}
                hashtags={["artofmemory", "memoryleague"]}
            >
                <TwitterIcon size={size} round={true} />
            </TwitterShareButton>{" "}
            <LinkedinShareButton
                aria-label="Share on LinkedIn"
                url={createShareURL("linkedin", url)}
                title={title}
                summary={excerpt}
                source="Art of Memory"
            >
                <LinkedinIcon size={size} round={true} />
            </LinkedinShareButton>{" "}
            <WhatsappShareButton
                aria-label="Share on WhatsApp"
                url={createShareURL("whatsapp", url)}
                title={title}
            >
                <WhatsappIcon size={size} round={true} />
            </WhatsappShareButton>{" "}
            <TelegramShareButton
                aria-label="Share on Telegram"
                url={createShareURL("telegram", url)}
                title={title}
            >
                <TelegramIcon size={size} round={true} />
            </TelegramShareButton>{" "}
            <RedditShareButton
                aria-label="Share on Reddit"
                url={createShareURL("reddit", url)}
                title={title}
            >
                <RedditIcon size={size} round={true} />
            </RedditShareButton>{" "}
            <PocketShareButton
                aria-label="Save to Pocket"
                url={createShareURL("pocket", url)}
                title={title}
            >
                <PocketIcon size={size} round={true} />
            </PocketShareButton>
        </aside>
    );
}
